var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attachment-area"},[(_vm.linkedList.length)?_c('BaseResultBoxSection',{ref:"linkedSection",attrs:{"entry-list":_vm.linkedList,"message-text":_vm.$t('form-view.deleteLinkedText'),"message-subtext":_vm.$t('form-view.deleteLinkedSubtext'),"header-text":_vm.$t('form-view.attachedEntries'),"show-action-button-boxes":true,"is-loading":_vm.entriesLoading,"selected-list":_vm.selectedEntries,"edit-mode":_vm.editModeActive === 'entry',"action-buttons-config":[{
      text: 'form-view.deleteLinked',
      icon: 'waste-bin',
      value: 'delete',
      display: !_vm.selectedEntries.length ? 'top' : 'all',
      disabled: !_vm.selectedEntries.length,
    }],"select-options-text":{
      selectAll: _vm.$t('selectAll'),
      selectNone: _vm.$t('selectNone'),
      entriesSelected: _vm.$t('entriesSelected', { type: _vm.$tc('entry', _vm.selectedEntries.length) })
    }},on:{"update:selectedList":function($event){_vm.selectedEntries=$event},"update:selected-list":function($event){_vm.selectedEntries=$event},"update:edit-mode":function($event){_vm.editModeActive = 'entry'},"submit-action":_vm.deleteLinked},scopedSlots:_vm._u([{key:"resultBox",fn:function(props){return [_c('BaseImageBox',{key:props.item.id,staticClass:"linked-base-box",attrs:{"selectable":props.selectActive,"selected":props.isEntrySelected(props.item),"title":props.item.to.title,"subtext":props.item.to.subtitle,"description":props.item.description,"image-url":props.item.to.image ? _vm.getImagePath(props.item.to.image) : '',"show-title":""},on:{"select-triggered":function($event){return props.entrySelected(props.item.id, $event)},"clicked":function($event){return _vm.goToLinked(props.item.to.id)}}})]}}],null,false,2080536278)}):_vm._e(),(_vm.attachedList.length)?_c('BaseResultBoxSection',{ref:"fileSection",attrs:{"draggable":true,"entry-list":_vm.attachedList,"message-text":_vm.$t('form-view.fileActionText'),"message-subtext":_vm.$t('form-view.fileActionSubtext'),"header-text":_vm.$t('form-view.attachedFiles'),"edit-mode":_vm.editModeActive === 'file',"is-loading":_vm.filesLoading || _vm.getIsArchivalBusy,"selected-list":_vm.selectedFiles,"select-options-text":{
      selectAll: _vm.$t('selectAll'),
      selectNone: _vm.$t('selectNone'),
      entriesSelected: _vm.$t('entriesSelected', { type: _vm.$tc('entry', _vm.selectedEntries.length) })
    },"show-action-button-boxes":true,"action-buttons-config":_vm.attachedFilesActionButtonsConfig,"use-options-button-on":"always"},on:{"update:selectedList":function($event){_vm.selectedFiles=$event},"update:selected-list":function($event){_vm.selectedFiles=$event},"entries-changed":_vm.orderAction,"submit-action":_vm.checkFileActioning,"update:edit-mode":function($event){_vm.editModeActive = 'file'}},scopedSlots:_vm._u([{key:"optionsMessageAreaAfter",fn:function(){return [_c('div',{staticClass:"text-small"},[_vm._v(" "+_vm._s(_vm.$t('form-view.fileActionSubtextDrag'))+" ")]),(_vm.pendingAction === 'license' && _vm.selectedFiles.length)?_c('div',{staticClass:"attachments__license-drop-down-area"},[_c('BaseDropDown',{staticClass:"attachments__license-drop-down",attrs:{"options":_vm.licenses,"show-label":false,"label":_vm.$t('form-view.selectLicense'),"placeholder":_vm.$t('form-view.selectLicense'),"language":_vm.$i18n.locale,"value-prop":"source"},model:{value:(_vm.licenseSelected),callback:function ($$v) {_vm.licenseSelected=$$v},expression:"licenseSelected"}}),_c('BaseButton',{staticClass:"license-button",attrs:{"disabled":!Object.keys(_vm.licenseSelected).length,"text":_vm.$t('form-view.licenseButton'),"icon-position":"right","icon":"check-mark","button-style":"secondary"},on:{"clicked":_vm.saveFileMeta}})],1):_vm._e()]},proxy:true},{key:"resultBox",fn:function(props){return [_c('BaseImageBox',{key:props.item.id,staticClass:"linked-base-box",attrs:{"show-title":true,"selectable":props.selectActive,"selected":props.isEntrySelected(props.item),"title":props.item.original ? _vm.getFileName(props.item.original) : props.item.id,"subtext":_vm.getLicenseLabel(props.item.license),"description":_vm.getFileType(props.item),"image-url":_vm.getImagePath(props.item.thumbnail
          || props.item.cover, _vm.imageHover[props.index]),"box-ratio":100,"box-text":_vm.generateBoxText(props.item.metadata)},on:{"select-triggered":function($event){return props.entrySelected(props.item.id,
                                               $event, props.item.published,
                                               props.item.archive_URI)},"clicked":function($event){return _vm.$emit('show-preview', props.item)}},nativeOn:{"mouseenter":function($event){return _vm.changeVideoHoverState($event, props.index, true)},"mouseleave":function($event){return _vm.changeVideoHoverState($event, props.index, false)}}},[_c('template',{slot:"footerLeft"},[(props.item.featured)?_c('base-icon',{staticClass:"status-icon",attrs:{"name":"subscribe","title":_vm.capitalizeString(_vm.$t('notify.featured')),"aria-title":_vm.capitalizeString(_vm.$t('notify.featured')),"aria-description":_vm.iconDescription('featured', props.item.original)}}):_vm._e()],1),_c('template',{slot:"footer"},[(props.item.published || props.item.archive_id ||
              props.item.archival_in_progress)?[_c('div',{staticClass:"status-icons"},[(props.item.published)?_c('base-icon',{staticClass:"status-icon",attrs:{"name":"eye","title":_vm.capitalizeString(_vm.$t('notify.publishd')),"aria-title":_vm.capitalizeString(_vm.$t('notify.publishd')),"aria-description":_vm.iconDescription('published', props.item.original)}}):_vm._e(),(props.item.archive_id && _vm.getIsArchivalEnabled)?_c('base-icon',{staticClass:"status-icon",attrs:{"name":"archive-sheets","title":_vm.capitalizeString(_vm.$t('archival.archived')),"aria-title":_vm.capitalizeString(_vm.$t('archival.archived')),"aria-description":_vm.iconDescription('archived', props.item.original)}}):_vm._e(),(!props.item.archive_id && _vm.getIsArchivalEnabled
                  && props.item.archival_in_progress)?_c('base-icon',{staticClass:"status-icon",attrs:{"name":"archive-arrow","title":_vm.capitalizeString(_vm.$t('archival.submitted')),"aria-title":_vm.capitalizeString(_vm.$t('archival.submitted')),"aria-description":_vm.iconDescription('archived', props.item.original)}}):_vm._e()],1)]:_vm._e()],2)],2)]}}],null,false,3279839102)}):_vm._e(),(_vm.parentList.length)?_c('BaseResultBoxSection',{ref:"parentSection",attrs:{"entry-list":_vm.parentList,"message-text":_vm.$t('form-view.deleteLinkedText'),"message-subtext":_vm.$t('form-view.deleteLinkedSubtext'),"header-text":_vm.$t('form-view.parentEntries'),"show-action-button-boxes":true,"is-loading":_vm.entriesLoading,"selected-list":_vm.selectedEntries,"edit-mode":_vm.editModeActive === 'parent',"action-buttons-config":[{
      text: 'form-view.deleteParents',
      icon: 'waste-bin',
      value: 'delete',
      display: !_vm.selectedEntries.length ? 'top' : 'all',
      disabled: !_vm.selectedEntries.length,
    }],"select-options-text":{
      selectAll: _vm.$t('selectAll'),
      selectNone: _vm.$t('selectNone'),
      entriesSelected: _vm.$t('entriesSelected', { type: _vm.$tc('entry', _vm.selectedEntries.length) })
    }},on:{"update:selectedList":function($event){_vm.selectedEntries=$event},"update:selected-list":function($event){_vm.selectedEntries=$event},"update:edit-mode":function($event){_vm.editModeActive = 'parent'},"submit-action":_vm.deleteLinked},scopedSlots:_vm._u([{key:"resultBox",fn:function(props){return [_c('BaseImageBox',{key:props.item.id,staticClass:"linked-base-box",attrs:{"selectable":props.selectActive,"selected":props.isEntrySelected(props.item),"title":props.item.parent.title,"subtext":props.item.parent.subtitle,"description":props.item.description,"image-url":props.item.parent.image ? _vm.getImagePath(props.item.parent.image) : '',"show-title":""},on:{"select-triggered":function($event){return props.entrySelected(props.item.id, $event)},"clicked":function($event){return _vm.goToLinked(props.item.parent.id)}}})]}}],null,false,2953620689)}):_vm._e(),(_vm.showArchivalUnsavedPopUp)?_c('archival-unsaved-pop-up',{attrs:{"is-pop-up-open":_vm.showArchivalUnsavedPopUp},on:{"cancel-unsaved":function($event){_vm.showArchivalUnsavedPopUp = false},"save-form":function($event){return _vm.saveDataBeforeArchival()}}}):_vm._e(),(_vm.showArchivalValidationPopUp)?_c('archival-validation-pop-up',{ref:"validationPopUp",attrs:{"is-pop-up-open":_vm.showArchivalValidationPopUp},on:{"cancel-validation":_vm.cancelArchivalValidation,"next-step":_vm.revalidateArchival}}):_vm._e(),(_vm.showArchivalAgreementPopUp)?_c('archival-agreement-pop-up',{attrs:{"is-pop-up-open":_vm.showArchivalAgreementPopUp,"selected-filenames":_vm.selectedFileNames},on:{"cancel-agreement":_vm.cancelArchivalAgreement,"next-step":_vm.proceedToArchival}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }