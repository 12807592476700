var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['base-row',
           { 'base-row-multiple-buttons':
             _vm.buttons.filter((button) => button.visible).length > 1 }]},[(_vm.showTitle || _vm.showSearch)?_c('div',{staticClass:"base-row-header"},[(!_vm.showSearch)?_c('div',{staticClass:"base-row-header__text-wrapper"},[_c('BaseMenuEntry',{attrs:{"entry-id":'asingleentry',"icon":'file-object',"title":_vm.showOverlay ? '' : _vm.title,"title-bold":true,"is-activatable":false,"subtext":_vm.type,"show-thumbnails":false}}),(_vm.showRemoveButton)?_c('div',{staticClass:"form-button form-reset-button",on:{"click":function($event){return _vm.$emit('remove')}}},[_c('BaseIcon',{staticClass:"form-reset-button__icon",attrs:{"name":"remove"}})],1):_vm._e()],1):_vm._e(),(_vm.showSearch)?_c('BaseSearch',{attrs:{"data-e2e-import-search-box":"","label":"SearchInput","placeholder":_vm.$t('import.searchCatalogueText'),"show-image":""},on:{"input":function($event){return _vm.$emit('search', _vm.searchText)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"base-row-buttons"},[(_vm.showMobileBackButton || _vm.showBackButton)?_c('div',{class:[
        'form-button',
        'form-back-button',
        'mobile-elements',
        { 'form-button-child' : _vm.showBackButton }
      ]},[_c('BaseButton',{staticClass:"form-button-inner",attrs:{"text":_vm.unsavedChanges ? _vm.$t('cancel') : _vm.$t('back'),"icon":_vm.unsavedChanges ? '' : 'arrow-left',"icon-size":"small","button-style":"row"},on:{"clicked":_vm.cancel}})],1):_vm._e(),(_vm.buttons)?[_vm._l((_vm.buttons),function(button,index){return [(button.visible)?_c('div',{key:index,class:['form-button',
                   'form-save-button',
                   'form-save-button-' + (index + 1)]},[_c('BaseButton',{staticClass:"form-button-inner",attrs:{"active":button.unsavedChanges,"text":_vm.$t(button.text),"disabled":_vm.dbRequestOngoing || button.disabled,"icon":_vm.isSaving ? '' : button.icon,"button-type":"submit","icon-size":"small","button-style":"row"},on:{"clicked":function($event){return _vm.save(button.action)}}},[(_vm.isSaving)?_c('template',{slot:"left-of-text"},[_c('span',{staticClass:"save-loader"},[_c('BaseLoader')],1)]):_vm._e()],2)],1):_vm._e()]})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }