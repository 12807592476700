var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseOptions',{attrs:{"show-options":_vm.showOptions,"options-button-icon":{
    show: 'options-menu',
    hide: 'remove',
  },"options-button-text":{
    show: 'options',
    hide: 'options',
  },"use-options-button-on":"mobile"},on:{"update:showOptions":function($event){_vm.showOptions=$event},"update:show-options":function($event){_vm.showOptions=$event}}},[_c('template',{slot:"options"},[_c('div',{staticClass:"base-form-options__options"},[(_vm.getIsArchivalEnabled && _vm.getCurrentItemData && _vm.getCurrentItemData.archive_URI)?_c('BaseDropButton',{attrs:{"buttons":_vm.archivalDropButtons,"expand-button-label":"Show more actions","primary-button":_vm.primaryArchivalButton},on:{"clicked":_vm.runArchivalAction}},[(_vm.getIsArchivalBusy)?_c('template',{slot:"right-of-text"},[_c('span',{staticClass:"archive-loader"},[_c('BaseLoader')],1)]):_vm._e()],2):_vm._e(),(!_vm.isPublished || !_vm.showroomId)?_c('BaseButton',{attrs:{"disabled":_vm.isNewForm,"text":_vm.isPublished ? _vm.$tc('offline') : _vm.$tc('publish'),"has-background-color":false,"button-text-wrap":false,"icon-size":"large","icon":"eye","button-style":"single"},on:{"clicked":function($event){return _vm.$emit('action-entry', _vm.isPublished ? 'offline' : 'publish')}}}):_vm._e(),(_vm.isPublished && _vm.showroomId)?_c('BaseDropButton',{attrs:{"disabled":_vm.isNewForm,"buttons":[{
          label: _vm.$tc('offline'),
          action: 'offline',
          icon: 'eye',
        }, {
          label: _vm.$tc('form-view.showroomButton'),
          action: 'openInShowroom',
          icon: 'share',
        }],"primary-button":"offline"},on:{"clicked":function($event){return _vm.$emit('action-entry', $event)}}}):_vm._e(),_c('BaseButton',{attrs:{"disabled":_vm.isNewForm,"text":_vm.$tc('delete'),"has-background-color":false,"button-text-wrap":false,"icon-size":"large","icon":"waste-bin","button-style":"single"},on:{"clicked":function($event){return _vm.$emit('action-entry', 'delete')}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }